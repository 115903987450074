@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('./fonts/Circe-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Circe';
	src: url('./fonts/Circe-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
}