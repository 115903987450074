@import-normalize;
@import 'reset';
@import './fonts/fonts';
@import './variables/base';
@import './variables/variables';
@import 'variables/global';
// @import 'themes/dark';
// @import 'themes/normal';
// @import 'fonts/fonts.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap');

.app {
	min-height: 100vh;
}

.content-page {
	display: flex;
}

.page-wrapper {
	flex-grow: 1;
	padding: 20px;
}
